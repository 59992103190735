import React from 'react'
import { Link } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import Common from '../../../context/Common'
import { modelRequest } from '@stem-sims/nexus'
import message from '../../../components/message/message'

class Subscription extends React.Component {
    static contextType = Common
    state = {
        students: 0,
        loading: true
    }

    componentDidMount() {
        modelRequest.get("/api/status").then((data) => {
            this.setState({ students: data.active })
        }).catch((err) => {
            message.error(err)
        }).finally(() => this.setState({ loading: false }))
    }

    render() {
        var { students } = this.state
        return (
            <React.Fragment>
                <h3>Current Subscription:</h3>
                <div className="form-horizontal">
                    <div className="mb-3 row">
                        <label className="col-6 control-label text-end"><span className="lead float-right">Access</span></label>
                        <div className="col-6">
                            {this.state.loading ?
                                <div className="float-left">
                                    <ClipLoader
                                        margin={10}
                                        size={20}
                                        color={"#123abc"}
                                        loading={this.state.loading} />
                                </div>
                                :

                                <p className="form-control-static lead text-start">{students} student{students > 1 ? "s" : ""} {students === 0 ? <span className="glyphicon glyphicon-remove"></span> : ""}</p>
                            }
                        </div>
                    </div>
                </div>
                <div className={`row ${this?.context?.currentOrg?.personal ? "" : "d-none"}`}>
                    <div className="col-sm-6">
                        <Link to="/account/subscription/share" className="btn btn-secondary btn-lg">Share Subscription</Link>
                    </div>

                    <div className="col-sm-6">
                        <Link to="/account/subscription/share/manage" className="btn btn-secondary btn-lg">Manage Sharing Settings</Link>
                    </div>
                </div>


                <hr className="strong" />


                {/* <h3>Future Subscriptions:</h3>

                <div className="form-horizontal">
                    <div className="mb-3 row">
                        <label className="col-6 control-label text-end">Last Subscription Expiration</label>
                        
                        <div className="col-6">
                            <p className="form-control-static text-start"><?php echo $accessExpiration; ?><?php echo $accessExpirationAlert; ?></p>
                        </div>
                    </div>
                </div> */}

                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <Link to="/subscribe" className="btn btn-secondary btn-lg">Renew Your Subscription</Link>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Subscription
