import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import  Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import buildQuery from '../helpers/buildQuery'
import * as statusModel from '../shared/routes/statusRoutes'
import * as accountModel from '../shared/routes/accountInfoRoutes'
import * as studentsModel from '../shared/routes/studentRoutes'
import { Student } from '../shared/types/studentTypes'
import Edit, { EditForm, EditLicenseModal } from '../pages/students/components/Edit'
import Archive from '../pages/students/components/Archive'
import Share from '../pages/students/components/Share'
import Transfer from '../pages/students/components/Transfer'
import Delete from '../pages/students/components/Delete'
import Add from '../pages/students/components/Add'
import RemoveShared from '../pages/students/components/Remove'
import { ReactTable, useModel } from '@stem-sims/nexus'
import { Context } from '../pages/students/components/StudentsContext'
import { Row } from 'react-bootstrap'
import { ToolTip } from '../pages/students/components/EditLicenses'
import { BeatLoader } from 'react-spinners'
import ExpirationMessage from './ExpirationMessage'

const MAX_LICENSES = 3
interface Props {
    customerUsername?: string,
    groupName?: string
}
type bulkActionButton = {
    name: string,
    className: string,
    action: (actionArg:any) => void
}
enum Modal {
    archive = "Archive",
    transfer = "Transfer",
    share = "Share",
    delete = "Delete",
    removeShared = "Remove Shared",
    editStudent = "Edit Student",
    addStudent = "Add Student",
    editLicense = "Edit License",
    none= ""
}
function Students(props: Props) {
    const [modal, setModal] = useState<Modal>()
    const [rowClicked, setClicked] = useState<Student>()
    const [selectedStudents, setSelectedStudents] = useState<Student[]>([])
    const [students, setStudents] = useState([])

    const { response: studentResponse, refreshModel: refreshStatus, loading: studentsLoading } = useModel({
        model: studentsModel.getAll
    })

    const { response: status, refreshModel: refreshStudents, loading: statusLoading } = useModel({
        model: statusModel.get
    })

    const { response: account, loading: accountLoading } = useModel({
        model: accountModel.get
    })

    React.useEffect(() => {
        if (studentResponse) {
            setStudents(
                studentResponse.map((student) => {
                    const hasActiveSubscription = !!student.licenses.find((license) => {
                        return license.status === "Active"
                    })
                    return {
                        ...student,
                        status: hasActiveSubscription ? "Active" : "Archived"
                    }
                })
            )
        }
    }, [studentResponse])

    const getStudents = () => {
        return Promise.all([
            refreshStatus(),
            refreshStudents()
        ])
    }

    function bulkActionModal(selectedStudents,modalToOpen) {
        const validSelection = !selectedStudents.some(student => student.owner || student.status === "Archived")
        if(!validSelection) {
            toast.error('Please unselect any shared or archived students')
            return
        }
        setSelectedStudents(selectedStudents)
        setModal(modalToOpen)
    }

    const share = (studentsToShare) => {
        bulkActionModal(studentsToShare,Modal.share)
    }
    const transfer = (studentsToTransfer) => {
        bulkActionModal(studentsToTransfer,Modal.transfer)
    }
    const columns = [
        {
            id: 'selection',
            disableSortBy: true,
            width:35,
            Header: ({ getToggleAllRowsSelectedProps }) => {
                const {indeterminate, ...rest} = getToggleAllRowsSelectedProps()
                return(<Form.Check type="checkbox" className="tableSelect"  {...rest}/>)
            },
            Cell: ({ row }) => {
                const {indeterminate,  ...rest} = row.getToggleRowSelectedProps()
                return (<Form.Check type="checkbox" className="tableSelect"  {...rest} />)
            }
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'License Type(s)',
            accessor: 'licenses',
            Cell: ({ row }) =>  {
                let student = row.original
                const studentIsShared = row.original.owner
                const showAdd = MAX_LICENSES > student.licenses.length && !studentIsShared
                return (
                    <div className="d-flex flex-wrap justify-content-center">
                        {student.licenses.map((license, index) => {
                            const licenseCss = (license.status === "Archived") ? "archived" : license.program.toLowerCase()
                            return (
                                <div style={{ marginBottom: '5px' }} key={license.program}>
                                    <div className={`license ${licenseCss} text-nowrap`} key={license.program}>
                                        {license.program}
                                    </div>
                                </div>
                            )
                        })}
                        {showAdd && 
                            <div className='d-flex align-items-center justify-content-center'>                                
                                <Button variant="outline-primary" size="sm" className='license' onClick={() => {
                                    setClicked(row.original)
                                    setModal(Modal.editLicense)
                                }}>
                                    <i className="fa fa-plus fa-xs"/>
                                </Button>
                            </div>
                        }
                    </div>
                )
            },
        },
        {
            Header: 'Status',
            accessor: 'status',
            maxWidth: 90,
        },
        {
            Header: 'Shared From',
            accessor: 'owner',
            Cell: ({ row }) => {
                return <p>{row.original?.owner?.username ?? ""}</p>
            }
        },
        {
            Header: 'View Records',
            Cell: ({ row }) =>  {
                let student = row.original
                let commonQuery = buildQuery({id: student.id, name: student.name, username: student.owner})
                return (
                    <Link to = {`/students/records/phonics/phonics?${commonQuery}`} className = "action-link">Records</Link>
                )
            },
            maxWidth: 125,
        },
        {
            Header: 'Edit Student',
            Cell: ({ row }) => (
                <Button
                    onClick={() => {
                        setClicked(row.original)
                        setModal(Modal.editStudent)
                    }}
                    variant="link"
                >
                    Edit
                </Button>
            ),
            maxWidth: 120,
        },
        {
            Header: 'Manage License',
            Cell: ({ row }) => {
                const studentHasActiveUsedLicense = row.original.licenses.some(license => license.status === "Active" && license.hasDoneProbe)
                const studentIsShared = row.original.owner
                const studentCanBeDeleted = !row.original.hasDoneProbe
                let manageLicenseDisplay = Modal.none;
                if(studentIsShared) {
                    manageLicenseDisplay = Modal.removeShared
                }
                else if(studentCanBeDeleted) {
                    manageLicenseDisplay = Modal.delete
                }
                else if(studentHasActiveUsedLicense) {
                    manageLicenseDisplay = Modal.archive
                }
                return (
                    <Button
                        onClick={() => {
                            setClicked(row.original)
                            setModal(manageLicenseDisplay)
                        }}
                        variant="link"
                    >
                        {manageLicenseDisplay}
                    </Button>
                )
            },
            maxWidth: 140,
        }
    ]
    const bulkActions: bulkActionButton[] = [
        {
            name: "Share",
            className: "btn-secondary",
            action: share
        }
    ]
    if(account?.privileges.includes("Administrator")) {
        bulkActions.push({
            name: "Transfer",
            className: "btn-secondary",
            action: transfer
        })
    }
    return (<>
        <Col id="student-roster" lg={10} className='align-self-center mw-100 mt-4'>

            {statusLoading || <>
                <ExpirationMessage status={status.math} program="math" />
                <ExpirationMessage status={status.reading} program="reading" />
                <ExpirationMessage status={status.emergentReader} program="emergent reader" />
            </>}

            <div className="d-md-flex mb-4">
                <div className="d-md-flex flex-1">
                    <div className="flex-column blue-panel rounded mx-3 mx-md-1">
                        <p>Licenses Available:</p>
                        <hr/>
                        {statusLoading ?
                            <BeatLoader size={10} color="#123abc"/> : <>
                            <p>Reading: <b className="gl-blue">{status.reading.available}</b></p>
                            <p >Math: <b className="gl-blue">{status.math.available}</b></p>
                            <p className="mb-0">Emergent Reader: <b className="gl-blue">{status.emergentReader.available}</b></p>
                        </>}
                    </div>
                </div>
                <h1 className='d-md-flex text-center py-3'>Manage Students</h1>
                <Row className = "flex-1 ">
                    <Col className = {`d-inline-block text-end`}>
                        <ToolTip title="student info">
                            <Link to="/students/share/manage" className="btn btn-secondary mx-2 mt-2">
                                <i className="fas fa-share-alt me-2"/>
                                Share Settings
                            </Link>
                        </ToolTip>
                        {statusLoading ||
                        <ToolTip disabledTip={"You have no remaining licenses"} disabled={status.reading.available === 0 && status.math.available === 0 && status.emergentReader.available === 0} title="Add Student Menu">
                            <Button
                                onClick={() => setModal(Modal.addStudent)}
                                disabled={status.reading.available === 0 && status.math.available === 0 && status.emergentReader.available === 0}
                                variant="secondary mx-2 mt-2"
                            >
                                <i className="fas fa-plus me-2"/>
                                Add Student
                            </Button>
                        </ToolTip>
                        }
                    </Col>
                </Row>
            </div>
            <div className='d-flex flex-column align-items-center mt-3'>
                {studentsLoading ?
                    <BeatLoader size={20} color="#123abc"/> :
                    students.length > 0 ?
                        <ReactTable columns={columns} data={students} bulkActions={bulkActions} sortBy={[{ id: "status" }, { id: "owner", desc: true }]}></ReactTable>
                    :
                        <h2>Click the Add Student button to create your roster!</h2>
                }
            </div>
            <Context.Provider
                value={{
                    selectedStudent: rowClicked,
                    selectedStudents: selectedStudents,
                    students: studentResponse,
                    refreshStudents: getStudents,
                    hideModals: () => setModal(Modal.none)
                }}
            >
                <Archive show={modal === Modal.archive} />
                <RemoveShared show={modal === Modal.removeShared} />
                <Share show={modal === Modal.share} />
                <Transfer show={modal === Modal.transfer} />
                <Delete show={modal === Modal.delete} />
                <Edit show={modal === Modal.editStudent} EditModal={EditForm}/>
                <Edit show={modal === Modal.editLicense} EditModal={EditLicenseModal} />
                <Add show={modal === Modal.addStudent} />
            </Context.Provider>
        </Col>
    </>)
}

export default Students
