import React, { Dispatch, SetStateAction } from "react"
import { useHistory } from "react-router-dom"
import Image from "react-bootstrap/Image"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import blugBg from "../../content/images/blue_bg.svg"
import redBg from "../../content/images/red_bg.svg"
import MathCard from "../../pages/subscribe/MathCard"
import ReadingCard from "../../pages/subscribe/ReadingCard"
import EmergentReaderCard from "../../pages/subscribe/EmergentReaderCard"

interface Props {
    modalType: string
    setModalType: Dispatch<SetStateAction<string>>
}

export default function PurchaseModal({ modalType, setModalType }: Props) {
    const history = useHistory()

    return (<Modal show={!!modalType} onHide={()=>setModalType("")} size="lg">
        {modalType === "Reading" && <Image src={blugBg} className="position-absolute w-100"></Image>}
        {modalType === "Math" && <Image src={redBg} className="position-absolute w-100"></Image>}
        {modalType === "Emergent Reader" && <Image src={blugBg} className="position-absolute w-100"></Image>}
        <Modal.Body>
            <Row className="align-items-between">
            <Col lg={6} className="d-flex flex-column py-3 me-2">
                <h2 className="h1 h-50 vertical-align move-forward text-light mx-3 pb-5">
                    No Remaining {modalType} Licenses
                </h2>
                <div className="h-50 vertical-align flex-column text-center py-2">
                    <p>You currently do not have any {modalType} Licenses</p>
                    <p className="pb-3">Would you like to purchase {modalType} Licenses?</p>
                    <div>
                        <Button onClick={()=>setModalType("")} variant="secondary" className="mx-3">Cancel</Button>
                        <Button onClick={()=>history.push("/subscribe")} size="sm" className="mx-3">Go to Purchase Page</Button>
                    </div>
                </div>
            </Col>
                {modalType === "Reading" && <ReadingCard />}
                {modalType === "Math" && <MathCard />}
                {modalType === "Emergent Reader" && <EmergentReaderCard />}
            </Row>
        </Modal.Body>
    </Modal>)
}
