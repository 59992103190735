import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import './styles/variables.scss';
import './styles/root.scss';
import './styles/common.scss';
import './styles/app.scss';
import './styles/home.scss';
import './styles/probes.scss';
import './styles/studentRoster.scss'
import './styles/subscribe.scss'
import './styles/records.scss'
import './styles/navbar.scss'
import '../node_modules/@stem-sims/nexus/src/styles/reactTable.scss'
import '../node_modules/@stem-sims/great-leaps-react-common/src/components/Probes/probe.scss'
import './styles/dashboard.scss'

import Template from './components/Template';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalErrorBoundary from './components/GlobalErrorBoundary';
import * as organizationSwitch from './shared/routes/organizationSwitchRoutes'

import {
  Router,
  Switch,
  Route,
  useLocation,
  Redirect
} from "react-router-dom";
import redirect from './components/redirect/redirect'
import readabilityPDF from './content/files/readability-of-stories.pdf';
import './content/images/images.css';

import MessageContainer from './components/message/MessageContainer'
import RedirectContainer from './components/redirect/RedirectContainer'
import history from './helpers/history'

import Probes from './routers/Probes';
import StoriesDOK from './pages/probes/StoriesDOK';
import Goal from './pages/probes/Goal';
import RecordsPage from './pages/records/RecordsPage';
import Students from './pages/students/Students'
import Home from './pages/home/Home';
import TermsOfUse from './pages/legal/TermsOfUse';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import FrequentlyAskedQuestions from './pages/help/FrequentlyAskedQuestions';
import Help from './pages/help/Help';
import OurStory from './pages/about/OurStory';
import TheProgram from './pages/about/TheProgram';
import UseCases from './pages/about/UseCases';
import Research from './pages/about/Research';
import Testimonials from './pages/about/Testimonials';
import Dashboard from './pages/home/Dashboard';
import Subscribe from './pages/subscribe/Subscribe';
import Summary from './pages/subscribe/Summary';
import Choose from './pages/subscribe/Choose';
import PurchaseOrder from './pages/subscribe/PurchaseOrder';
import OrganizationCreate from './pages/organization/create/Create';
import OrganizationInvite from './pages/organization/invite/Invite';
import ManageOrg from './pages/manage/ManageOrg';
import InviteInstructors from './pages/manage/instructor/Invite';
import CreateGroup from './pages/manage/group/Add';
import RemoveGroup from './pages/manage/group/Remove';
import EditGroup from './pages/manage/group/Edit';
import AssignUser from './pages/manage/instructor/AssignUser';
import LimitStudentInstructor from './pages/manage/instructor/Limit';

import SignUp from './pages/account/sign-up/SignUp';
import SignUpSaml from './pages/account/sign-up/SignUpSaml';
import SignUpSuccess from './pages/account/sign-up/success/Success';

import TransferStudent from './pages/students/Transfer/Transfer';
import RemoveStudentShare from './pages/students/Share/Remove/Remove';
import ManagedSharedStudents from './pages/students/Share/Manage/Manage';
import EditSharedStudent from './pages/students/Share/Edit/EditShared';
import RevokeSharedStudent from './pages/students/Share/Revoke/Revoke';
import Account from './routers/Account'
import ShareSubscriptions from './pages/account/subscription/share/Share'
import ManageSubscriptions from './pages/account/subscription/share/manage/Manage'
import EditSubscriptions from './pages/account/subscription/share/edit/Edit'
import Login from './routers/Login'
import ProbesList from './routers/ProbesList';
import StudentDashboard from './pages/student/Dashboard'
import StudentProbes from './routers/StudentProbes'
import AdminHome from './pages/admin/AdminHome';
import CreateTransaction from './pages/admin/transaction/CreateTransaction';
import CreateTransactionSuccess from './pages/admin/transaction/CreateTransactionSuccess';
import ActivationCodesHome from './pages/admin/activation-codes/ActivationCodesHome';
import GenerateCodes from './pages/admin/activation-codes/GenerateCodes'
import GenerateCodesSuccess from './pages/admin/activation-codes/GenerateCodesSuccess';
import ForceActivateCodes from './pages/admin/activation-codes/ForceActivateCodes';
import ForceActivateCodesSuccess from './pages/admin/activation-codes/ForceActivateCodesSuccess';
import SendCodesEmail from './pages/admin/activation-codes/SendCodesEmail';
import SendCodesEmailSuccess from './pages/admin/activation-codes/SendCodesEmailSuccess';
import SalesCodeHome from './pages/admin/sales-code/SalesCodeHome';
import GenerateSalesCode from './pages/admin/sales-code/GenerateSalesCode';
import GenerateSalesCodeSuccess from './pages/admin/sales-code/GenerateSalesCodeSuccess';
import SendSalesCodeEmail from './pages/admin/sales-code/SendSalesCodeEmail';
import SendSalesCodeEmailSuccess from './pages/admin/sales-code/SendSalesCodeEmailSuccess';
import SendEmailHome from './pages/admin/send-email/SendEmailHome';
import CreateDiscountCode from './pages/admin/discount-code/CreateDiscountCode';
import CreateDiscountCodeSuccess from './pages/admin/discount-code/CreateDiscountCodeSuccess';
import CreateAccount from './pages/admin/account/CreateAccount';
import CreateAccountSuccess from './pages/admin/account/CreateAccountSuccess';
import ForceLogin from './pages/admin/account/ForceLogin';

import { CustomerRouteSkeleton as CustomerRoute, GuestRouteSkeleton as GuestRoute, StudentRouteSkeleton as StudentRoute, AdminRouteSkeleton as AdminRoute, ManagerRouteSkeleton as ManagerRoute } from './helpers/Authenticated'
import authentication from './helpers/authentication'
import RecoverByUsername from './pages/account/recover/RecoverByUsername';
import RecoverByEmail from './pages/account/recover/RecoverByEmail';
import Reset from './pages/account/password/reset/Reset';
import VerifyEmailAddress from './pages/account/verify-email-address/VerifyEmailAddress';
import AcceptTransferRequest from './pages/students/Transfer/accept/Accept';
import Processing from './pages/subscribe/Processing';
import NotFound from './pages/NotFound';
import ResendVerifyEmail from './pages/account/verify-email-address/resend/Resend';
import Unsubscribe from './pages/account/unsubscribe/Unsubscribe'

import Common from './context/Common'
import ReactGA from 'react-ga4'
import message from './components/message/message';
import Loader from './components/Loader'
import { mathSubjects } from './shared/types/mathTypes';
import AwarenessOralExpression from './pages/probes/AwarenessOralExpression';



function log(location) {
  ReactGA.set({ hitType: "pageview", page: location.pathname }); // Update the user's current page
}

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// closes mobile navbar when a link is clicked. must be called whenever navbar links change (so when permissions actually change) to attach listener to new links.
function FixNavbar() {
  React.useEffect(() => {
    window.$(function () {
      window.$('.navbar-collapse a, .navbar-brand').not("[href='#']").on('click', function () {
        window.$('.navbar-collapse').collapse('hide');
      });
    });
  });

  return null;
}
function renderFile(filePath) {
  window.location = filePath;

  return (
    <React.Fragment>
      <br />
      <p>You should have been automatically redirected to the file.</p>
      <p>If you have not been redirected, please click <a href={filePath} target="_blank" rel="noreferrer">here</a>.</p>
    </React.Fragment>
  )
}

class App extends React.Component {
  state = {
    auth: null,
    currentOrg: null,
    switching: false
  }

  _isMounted = false
  updatePermissions = () => {
    return authentication.get()
      .then((data) => {
        if (!data) return
        if (this._isMounted) {
          this.setState({
            auth: data.user,
            currentOrg: data.currentOrganization
          })
        }
        // need to change to resolve data, but then need to change all implementations of updatePermissions()?
        // maybe not if currentOrg is always passed through props?
        // also, should currentOrg be added to context provider?
        return Promise.resolve(data.user)
      })
  }

  switchOrg = async (organizationID, groupName) => {
    window.scrollTo(0, 0);

    redirect.send("/dashboard", this.props, () => {
      setTimeout(async () => { // wait for dashboard redirect to finalize or page will try to update with bad data
        this.setState({
          switching: true
        })
        organizationSwitch.post(organizationID, groupName).then((res) => {
          message.success("You have switched to your new organization")
          this.updatePermissions()
        }).catch((err) => {
          message.error(err)
        }).finally(() => {
          this.setState({
            switching: false
          })
        })
      })
    })
  }
  componentDidMount() {
    this._isMounted = true
    this.updatePermissions()

    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TOKEN || "UA-000000-01")

    history.listen((location) => {
      this.updatePermissions()
      log(location)
    })

    log(window.location)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <GlobalErrorBoundary>
        <Router history={history}>
          <Loader visible={this.state.switching} text='Switching...' />
          {this.state.switching ? "" :
            <Template auth={this.state.auth} currentOrg={this.state.currentOrg} updatePermissions={this.updatePermissions} switchOrg={this.switchOrg} switchGroup={this.switchGroup}>
              <ErrorBoundary>
                <FixNavbar />
                <ScrollToTop />
                <Common.Provider value={{ auth: this.state.auth, currentOrg: this.state.currentOrg, updatePermissions: this.updatePermissions }}>
                  {/*<div className="alert alert-success">
                    50% OFF SALE! Use code 50%OFF at checkout to save BIG on your purchase! Offer valid through 4/19/24, terms and conditions may apply.
                  </div>*/}
                  <RedirectContainer />
                  <ToastContainer />
                  <Route component={MessageContainer} />
                  <Switch>

                    <Route exact path="/" component={Home} />
                    <StudentRoute exact path="/student/dashboard" component={StudentDashboard} />

                    {
                      ["phonics", "alphabet"].map((type) => {
                        return (
                          <StudentRoute exact path={`/student/probes/${type}/:probeNumber`} component={StudentProbes} props={{ type: type }} key={type} />)
                      })
                    }

                    <StudentRoute exact path={`/student/probes/phrases/:probeNumber`} component={StudentProbes} props={{ type: 'phrases' }} />

                    {
                      ["high", "middle", "elementary"].map((type) => {
                        return (
                          <StudentRoute exact path={`/student/probes/${type}/:probeNumber`} component={StudentProbes} props={{ type: type }} key={type} />
                        )
                      })
                    }

                    {
                      mathSubjects.map((type) => {
                        return (
                          <StudentRoute exact path={`/student/probes/${type}/:probeNumber`} component={StudentProbes} props={{ type: type }} key={type} />
                        )
                      })
                    }
                    <StudentRoute exact path="/student/goal" component={Goal} />

                    <GuestRoute exact path="/account/sign-up" component={SignUp} updatePermissions={this.updatePermissions.bind(this)} />
                    <GuestRoute exact path="/account/sign-up/saml" component={SignUpSaml} updatePermissions={this.updatePermissions.bind(this)} />
                    <GuestRoute exact path="/account/sign-up/success" component={SignUpSuccess} updatePermissions={this.updatePermissions.bind(this)} />
                    <GuestRoute exact path="/account/log-in/student" component={Login} props={{ type: "student" }} updatePermissions={this.updatePermissions.bind(this)} />
                    <GuestRoute exact path="/account/log-in/teacher" component={Login} props={{ type: "customer" }} updatePermissions={this.updatePermissions.bind(this)} />

                    <GuestRoute exact path="/account/recover/username" component={RecoverByUsername} />
                    <GuestRoute exact path="/account/recover/email" component={RecoverByEmail} />
                    <GuestRoute exact path="/account/password/reset" component={Reset} />
                    <GuestRoute exact path="/account/verify-email-address" component={VerifyEmailAddress} updatePermissions={this.updatePermissions.bind(this)} />
                    <GuestRoute exact path="/account/unsubscribe" component={Unsubscribe} />

                    <Route exact path="/legal/terms-of-use" component={TermsOfUse} />
                    <Route exact path="/legal/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/help" component={Help} />
                    <Route exact path="/help/frequently-asked-questions" component={FrequentlyAskedQuestions} />

                    {/* <Redirect exact from="/about/" to="/about/the-program" /> // doesn't work properly */}
                    <Route exact path="/about" render={(props) => <Redirect to="/about/the-program" />} />
                    <Route exact path="/about/our-story" component={OurStory} />
                    <Route exact path="/about/the-program" component={TheProgram} />
                    <Route exact path="/about/use-cases" component={UseCases} />
                    <Route exact path="/about/research" component={Research} />
                    <Route exact path="/about/testimonials" component={Testimonials} />

                    <GuestRoute exact path="/students/transfer/accept" component={AcceptTransferRequest} />

                    <CustomerRoute exact path="/dashboard" component={Dashboard} props={{ org: this.state.currentOrg?.name }} />

                    {/* route to manually redirect to file */}
                    <Route exact path="/file/readability" render={() => renderFile(readabilityPDF)} />

                    {/* 
                      * Student Management Pages
                      */}
                    <CustomerRoute exact path="/students" component={Students} />
                    <CustomerRoute exact path="/students/share/remove" component={RemoveStudentShare} />
                    <CustomerRoute exact path="/students/share/manage" component={ManagedSharedStudents} />
                    <CustomerRoute exact path="/students/share/edit" component={EditSharedStudent} />
                    <CustomerRoute exact path="/students/share/revoke" component={RevokeSharedStudent} />
                    <CustomerRoute exact path="/students/transfer" component={TransferStudent} />

                    {/* 
                      * Account Information Pages
                      */}
                    <CustomerRoute exact path="/account/information" component={Account} props={{ type: "information" }} />
                    <CustomerRoute exact path="/account/password" component={Account} props={{ type: "password" }} />
                    <CustomerRoute exact path="/account/subscription" component={Account} props={{ type: "subscription" }} updatePermissions={this.updatePermissions.bind(this)} />
                    <CustomerRoute exact path="/account/subscription/share" component={ShareSubscriptions} />
                    <CustomerRoute exact path="/account/subscription/share/manage" component={ManageSubscriptions} />
                    <CustomerRoute exact path="/account/subscription/share/edit" component={EditSubscriptions} />


                    {/* 
                      * Probe Pages
                      */}

                    <CustomerRoute exact path={`/probes/phrases/:probeNumber`} component={Probes} props={{ type: "phrases" }} />)
                    <CustomerRoute exact path={`/probes/phonics/:probeNumber`} component={Probes} props={{ type: "phonics" }} />)

                    {/* Tried to put these in one big loop using React.Fragment, but the switch was treating it as a rendered Component and rendered a blank page. */}


                    {
                      ["alphabet", "awareness"].map((type) => {
                        return (
                          <CustomerRoute
                            exact
                            path={`/probes/${type}/:probeNumber`}
                            component={Probes}
                            props={{
                              type: type,
                              program: "Emergent Reader",
                            }}
                            key={type}
                          />
                        )
                      })
                    }
                    {
                      ["phonics", "phrases"].map((type) => {
                        return (
                          <CustomerRoute exact path={`/probes/${type}`} component={ProbesList} props={{ type: type, program: "Reading"}} key={type}/>
                        )
                      })
                    }
                    {
                      ["alphabet", "awareness"].map((type) => {
                        return (
                          <CustomerRoute exact path={`/probes/${type}`} component={ProbesList} props={{ type: type, program: "Emergent Reader"}} key={type}/>
                        )
                      })
                    }

                    {
                      ["high", "middle", "elementary"].map((type) => {
                        return (
                          <CustomerRoute
                            exact
                            path={`/probes/stories/${type}/:probeNumber`}
                            component={Probes}
                            props={{
                              type: type
                            }}
                            key={type}
                          />
                        )
                      })
                    }

                    {
                      ["high", "middle", "elementary"].map((type) => {
                        return (
                          <CustomerRoute
                            exact
                            path={`/probes/stories/${type}`}
                            component={ProbesList}
                            props={{
                              type: type,
                              story: type,
                              program: "Reading"
                            }}
                            key={type}
                          />
                        )
                      })
                    }

                    {
                      mathSubjects.map((type) => {
                        return (
                          <CustomerRoute
                            path={`/probes/${type}/:probeNumber`}
                            component={Probes}
                            props={{
                              type: type
                            }}
                            key={type}
                            exact
                          />
                        )
                      })
                    }
                    {
                      mathSubjects.map((type) => {
                        return (
                          <CustomerRoute
                            exact
                            path={`/probes/${type}`}
                            component={ProbesList}
                            props={{
                              type: type,
                              program: "Math"
                            }}
                            key={type}
                          />
                        )
                      })
                    }

                    {
                      ["high", "middle", "elementary"].map((type) => {
                        return (
                          <CustomerRoute
                            exact
                            path={`/probes/stories/${type}/:probeNumber/success`}
                            component={StoriesDOK}
                            props={{
                              type: type
                            }}
                            key={type}
                          />
                        )
                      })
                    }

                    <CustomerRoute
                      exact
                      path="/probes/awareness/:probeNumber/oral-expression/:studentId"
                      component={AwarenessOralExpression}
                    />

                    {/* 
                      * Records Pages
                      */}
                    {["phonics", "alphabet"].map((type) => {
                      return <CustomerRoute
                        exact
                        path={`/students/records/phonics/${type}`}
                        component={RecordsPage}
                        props={{
                          type: type,
                          program: "Reading"
                        }}
                        key={type}
                      />
                    })}

                    <CustomerRoute
                      exact
                      path={`/students/records/phrases`}
                      component={RecordsPage}
                      props={{
                        type: "phrases",
                        program: "Reading"
                      }}
                    />

                    {
                      ["high", "middle", "elementary"].map((type) => {
                        return <CustomerRoute
                          exact
                          path={`/students/records/stories/${type}`}
                          component={RecordsPage}
                          props={{ type: type, program: "Reading", ...this.props }}
                          key={type}
                        />
                      })
                    }

                    {mathSubjects.map((type) => {
                      return <CustomerRoute
                        exact
                        path={`/students/records/${type}`}
                        component={RecordsPage}
                        props={{ type: type, program: "Math" }}
                        key={type}
                      />
                    })}

                    <GuestRoute exact path="/subscribe" component={Subscribe} props={{ purchaseView: "default" }} />
                    <CustomerRoute exact path="/subscribe/choose" component={Choose} />
                    <CustomerRoute exact path="/subscribe/add" component={Subscribe} props={{ purchaseView: "add" }} />
                    <CustomerRoute exact path="/subscribe/extend" component={Subscribe} props={{ purchaseView: "extend" }} />
                    <CustomerRoute exact path="/subscribe/extend/summary" component={Summary} />
                    <CustomerRoute exact path="/subscribe/purchase-order" component={PurchaseOrder} />
                    <CustomerRoute exact path="/subscribe/processing" component={Processing} />

                    <CustomerRoute exact path="/organization/create" component={OrganizationCreate} updatePermissions={this.updatePermissions.bind(this)} />
                    <CustomerRoute exact path="/organization/invite" component={OrganizationInvite} />

                    <ManagerRoute exact path="/manage" component={ManageOrg} currentOrg={this.state.currentOrg} props={{ org: this.state.currentOrg }} />
                    <ManagerRoute exact path="/manage/instructor/invite" currentOrg={this.state.currentOrg} component={InviteInstructors} />
                    <ManagerRoute exact path="/manage/group/create" currentOrg={this.state.currentOrg} component={CreateGroup} />
                    <ManagerRoute exact path="/manage/group/remove" currentOrg={this.state.currentOrg} component={RemoveGroup} />
                    <ManagerRoute exact path="/manage/group/edit" currentOrg={this.state.currentOrg} component={EditGroup} />
                    <ManagerRoute exact path="/manage/instructor/assign" currentOrg={this.state.currentOrg} component={AssignUser} />
                    <ManagerRoute exact path="/manage/instructor/limit" currentOrg={this.state.currentOrg} component={LimitStudentInstructor} />
                    <CustomerRoute exact path="/account/verify-email-address/resend" component={ResendVerifyEmail} />


                    {/*
                    * Admin Panel Pages
                    */}
                    <AdminRoute exact path="/admin" component={AdminHome} />
                    <AdminRoute exact path="/admin/create-transaction" component={CreateTransaction} />
                    <AdminRoute exact path="/admin/create-transaction/success" component={CreateTransactionSuccess} />
                    <AdminRoute exact path="/admin/activation-codes" component={ActivationCodesHome} />
                    <AdminRoute exact path="/admin/activation-codes/generate" component={GenerateCodes} />
                    <AdminRoute exact path="/admin/activation-codes/generate/success" component={GenerateCodesSuccess} />
                    <AdminRoute exact path="/admin/activation-codes/force-activate" component={ForceActivateCodes} />
                    <AdminRoute exact path="/admin/activation-codes/force-activate/success" component={ForceActivateCodesSuccess} />
                    <AdminRoute exact path="/admin/activation-codes/send-email" component={SendCodesEmail} />
                    <AdminRoute exact path="/admin/activation-codes/send-email/success" component={SendCodesEmailSuccess} />
                    <AdminRoute exact path="/admin/sales-code" component={SalesCodeHome} />
                    <AdminRoute exact path="/admin/sales-code/generate" component={GenerateSalesCode} />
                    <AdminRoute exact path="/admin/sales-code/generate/success" component={GenerateSalesCodeSuccess} />
                    <AdminRoute exact path="/admin/sales-code/send-email" component={SendSalesCodeEmail} />
                    <AdminRoute exact path="/admin/sales-code/send-email/success" component={SendSalesCodeEmailSuccess} />
                    <AdminRoute exact path="/admin/send-email" component={SendEmailHome} />
                    <AdminRoute exact path="/admin/discount-code" component={CreateDiscountCode} />
                    <AdminRoute exact path="/admin/discount-code/success" component={CreateDiscountCodeSuccess} />
                    <AdminRoute exact path="/admin/create-account" component={CreateAccount} />
                    <AdminRoute exact path="/admin/create-account/success" component={CreateAccountSuccess} />
                    <AdminRoute exact path="/admin/force-login" component={ForceLogin} />

                    <GuestRoute component={NotFound} />

                  </Switch>

                </Common.Provider>
              </ErrorBoundary>
            </Template>
          }
        </Router>
      </GlobalErrorBoundary>
    )
  }
}

export default App;
