import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import buildQuery from '../../helpers/buildQuery'
import groupBy from '../../helpers/groupBy'
import * as probeList from "../../shared/types/probeListTypes"

interface SectionProps {
    sections: probeList.ProbeListSection[]
    suggested: probeList.ProbeListSuggestedProbe
    suggestedStory: "high" | "middle" | "elementary"
    studentID: string
    probeBaseURL: string
    isSkillSet?: boolean
    probeClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, probeNumber: number) => any
}

function Sections({ sections, suggested, studentID, probeClick, probeBaseURL, suggestedStory }: SectionProps){

    const history = useHistory()

    const handlePrepClick = (event, probeNumber) => {
        event.preventDefault()
        event.stopPropagation()
        history.push({
            pathname: `/probes/awareness/${probeNumber - 1}/oral-expression/${studentID}`,
            search: `?id=${studentID}`,
            state: { view: "PrepOnlyView" }
        });
    }

    return (<div className = "row">
                <div className = "col-sm-10 offset-sm-1">
                    <div className = "panel-group" id = "accordion">
                    {
                        sections.map((section) =>
                        {
                            let probes: probeList.ProbeListProbe[] = []
                            groupBy(section.probes, "skillSet").forEach((value, key) => {
                                if (key === null) {
                                    // Add all probes without a skill set
                                    probes = probes.concat(value)
                                } else if (suggested.skillSet === key) {
                                    // add the suggested probe if it's in this skill set
                                    probes.push(value.find(({number}) => number === suggested.number))
                                } else {
                                    // Add the first probe of the skill set
                                    probes.push(value[0])
                                }
                            })
                            // sort the probes by probe number
                            probes.sort(({number: n1}, {number: n2}) => parseInt(n1 as any) - parseInt(n2 as any))
                            return <React.Fragment key={section.number}>
                                    <div className = {`card mb-1 ${section.number === suggested.section ? "active" : ""}`}>
                                        <a data-toggle = "collapse" data-parent = "#accordion" href = {`#section-${section.number}`}>
                                            <div className = "card-header">
                                                <h6 className = "card-title mb-0 text-center">Section - {section.number} {section.title} {section.number === 7 && "(Optional)"}</h6>
                                            </div>
                                        </a>
                                        <div id = {`section-${section.number}`} className = {`${section.number === suggested.section ? "" : "collapse"} `}>
                                            <div className = "card-body list-group p-0">
                                            {
                                                probes.map((probe) =>
                                                {
                                                    const isSkillSet = probe.skillSet !== null
                                                    const isSuggestedProbe = probe.number === suggested.number
                                                    const hasPrepInstructions = probe.hasPrepInstructions

                                                    const standardVariables = buildQuery({ id: studentID })
                                                    return  <Link
                                                                to = {`${probeBaseURL}/${probe.number}?${standardVariables}${suggestedStory ? `&suggestedStory=${suggestedStory}` : ""}`}
                                                                className = {`list-group-item list-group-item-action ${isSuggestedProbe ? "active" : ""}`}
                                                                onClick={(event) => { probeClick(event, probe.number) }}
                                                                key={probe.number}>
                                                                    <h5 className = "list-group-item-heading m-0" style = {{ pointerEvents: "none" }}>
                                                                        <strong>
                                                                            { isSkillSet ? 
                                                                                `Skill Set ${probe.skillSet}`
                                                                            : `Exercise ${probe.number}` }
                                                                        </strong>
                                                                    </h5>
                                                                    {hasPrepInstructions && <p onClick={(event) => handlePrepClick(event, probe.number)} className="btn btn-secondary px-5 awareness-prep-button">Pre-Exercise Instructions</p>}

                                                                    {isSkillSet && probe.skillSetTitle && <p className = "list-group-item-text m-0" style = {{ pointerEvents: "none" }}>{probe.skillSetTitle}</p>}
                                                                    
                                                                    {!isSkillSet && <p className = "list-group-item-text m-0" style = {{ pointerEvents: "none" }}>{probe.title}</p>}
                                                                </Link>
                                                })
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                        })
                    }
                    </div>
                </div>
            </div>)
}

export default Sections
