import { modelRequest } from "@stem-sims/nexus"

function remove({ orgId, customerUsername }: { orgId: string, customerUsername: string }) {
    return modelRequest.delete("/api/organizations/remove-instructor", {
        data: {
            id: orgId,
            customerUsername
        }
    })
}

const organizationRemoveInstructor = {
    remove
}

export default organizationRemoveInstructor
