


import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import Col from "react-bootstrap/Col"
import * as suggestedModel from "../../../shared/routes/suggestedRoutes"
import buildQuery from '../../../helpers/buildQuery'
import redirect from "../../../components/redirect/redirect"
import { FilterByInstructor } from '../../../components/StudentRoster'
import { ReactTable } from '@stem-sims/nexus'
import { toast } from 'react-toastify'

export default function DashboardRoster({ columns, data: students, validLicenses }) {

    const [filterValue, setFilterValue] = React.useState("")
    const [instructors, setInstructors] = React.useState([])

    const filteredStudentList = React.useMemo(() => {
        if (!students) {
            return []
        }
        return students.filter((student) => {
            const lowerCaseFilterValue = filterValue.toLowerCase()

            //filter out archived students
            if (student.licenses.every((license) => license.status === "Archived")) {
                return false
            }

            if (student.name.toLowerCase().indexOf(lowerCaseFilterValue) !== -1) {
                return true
            }

            //Allow filtering by owner's username
            if (student.owner && student.owner.username.toLowerCase().indexOf(lowerCaseFilterValue) !== -1) {
                return true
            }
            if (student.instructors.find((instructor) => instructor.toLowerCase().indexOf(lowerCaseFilterValue) !== -1)) {
                return true
            }
            return false
        })
    }, [students, filterValue])

    useEffect(() => {
        if (!students) return
        const instructorsByStudent: string[] = students.map((s) => s.instructors || [])
        const instructorNames = [].concat.apply([], instructorsByStudent) // .flat() is not supported in older browsers
        const uniqueInstructorsNames = instructorNames.filter((instruct, index, array) => (array.indexOf(instruct) === index)).sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        }) 
        setInstructors(uniqueInstructorsNames)
    }, [students])

    const onRowClick = async (row) => {
        let student = row.original
        let path = ""
        const hasMath = student.licenses.find((license) => license.program === "Math" && license.status === "Active")
        suggestedModel.get({ studentID: student.id }).then((suggest) => {
            if (hasMath && suggest.suggestedProgram === "Math" && validLicenses.includes("Math")) {
                path = "addition"
            } else {
                path = `${suggest.suggestedPhonicsType}`
            }
            redirect.send(`/probes/${path}?${buildQuery({ id: student.id })}`, {})
        }).catch((err) => {
            toast.error(err)
        })
    }

    columns.concat([
        /* to be added later GL-482 {
            Header: 'Grade',
            accessor: 'grade',
            maxWidth: 90,
        },*/
    ])

    return (<>
        {students?.length === 0 &&
            <div className="alert alert-info">
                Before you can start exercises with students, you must create a student. Head to the <Link to="/students">Manage Students</Link> to make your student.
            </div>
        }
        <Col sm={6} md={4}>
            <input
                placeholder="Search Students"
                id="student-search"
                className="form-control"
                onChange={(event) => {
                    setFilterValue(event.currentTarget.value)
                }}
            />
        </Col>
        <Col sm={6} md={3}>
            <FilterByInstructor instructors={instructors} setFilterValue={setFilterValue} />
        </Col>
        <ReactTable columns={columns} bulkActions={[]} data={filteredStudentList} sortBy={[{ id: "name" }]} clickRow={onRowClick as any} hideFilter />
    </>)
}
