import React from 'react'
import {toast} from 'react-toastify'
import Button from "react-bootstrap/Button"
import * as Yup from "yup"
import * as studentsModel from '../../../shared/routes/studentRoutes'
import { StudentInfo, ProgramsType, Programs} from '../../../shared/types/studentTypes'
import StudentForm from './StudentForm'
import * as statusModel from '../../../shared/routes/statusRoutes'
import { modelRequest, useModel } from '@stem-sims/nexus';
import Modal from 'react-bootstrap/Modal'
import { useRoster } from './StudentsContext'
import { Formik, Form as FormikForm } from 'formik';
import EditLicenses from './EditLicenses'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'

type EditProps = {
    show: boolean,
    EditModal: any
}

export function EditLicenseModal({submitEdit, disabledPrograms}) {
    const roster = useRoster()
    const programs =  roster.selectedStudent?.licenses?.map((license) => license.program) ?? []
    const { response: student, loading: studentLoading } = useModel({
        model: studentsModel.getOne,
        props: roster.selectedStudent.id
    })
    const validationSchema = Yup.object().shape({
        programs: Yup
            .array()
            .min(1, "At least one program must be selected.")
            .required(),
    })
    return(<>
    {!studentLoading &&
        <Formik
            initialValues={{programs: programs, ...student}}
            validationSchema={validationSchema}
            onSubmit={submitEdit}>
            {({ errors, touched, isSubmitting }) => (<FormikForm>
                <Modal.Header closeButton>Edit License</Modal.Header>
                <Modal.Body>
                    <Container className="text-center">
                        <EditLicenses disabledPrograms={disabledPrograms}/>
                        {!!errors.programs && touched.programs &&
                            <Form.Control.Feedback type="invalid" className="d-block">{errors.programs}</Form.Control.Feedback>
                        }
                        <div className="mt-2">
                            <Button
                                variant="secondary"
                                type="submit"
                                size="lg"
                                disabled={isSubmitting}
                            >
                                {"Edit License"}
                            </Button>
                        </div>
                    </Container>
                </Modal.Body>
            </FormikForm>)}
        </Formik>
    }
    </>)
}
type StudentAndPrograms = StudentInfo & {programs: ProgramsType[]}

function Edit({show,EditModal}: EditProps) {
    const roster = useRoster()
    const { response: status } = useModel({
        model: statusModel.get
    })

    let disabledPrograms = []
    disabledPrograms = Programs.filter((program) => {
        return cannotEditProgram(program)
    })
    const submitEdit = async ({programs,...values}: StudentAndPrograms) => {
        try {
            let updatedStudent: StudentInfo = {
                id: roster.selectedStudent.id,
                ...values
            }
            await modelRequest.post('/api/students/edit', {student: updatedStudent, programs: programs})
            roster.refreshStudents()
            roster.hideModals()
            toast.success("Your student has been updated.")
        }
        catch(error) {
            toast.error(error ?? "There has been an error updating your student.")
        }
    }
    function cannotEditProgram(program) {
        const isShared = roster.selectedStudent?.owner
        if(isShared) return true
        const license = roster.selectedStudent?.licenses.find(({program:licenseProgram}) => licenseProgram === program) // O(K) = O(1)
        if(!license) {
            const licenseAvailable = (status?.[program.toLowerCase()]?.available === 0)
            return licenseAvailable
        }
        return license.hasDoneProbe
    }
    return(<>
            <Modal
                show={show}
                onHide={roster.hideModals}
                size={"lg"}
                className="student-modal"
            >
                <EditModal submitEdit={submitEdit} disabledPrograms={disabledPrograms} />
            </Modal>
        </>
    )
}

export function EditForm({submitEdit,disabledPrograms}) {
    const roster = useRoster()
    const { response: student } = useModel({
        model: studentsModel.getOne,
        props: roster.selectedStudent.id
    })
    return (
        <StudentForm
            onSubmit={submitEdit}
            submitLabel="Save"
            initialValues={{
                programs: student?.licenses?.map((license) => license.program) ?? [],
                grade: student?.grade || "",
                group: student?.group || "",
                name: student?.name || "",
                birthDay: student?.birthDay || "",
                gender: student?.gender || "" as any,
                ethnicity: student?.ethnicity || "" as any,
                currentSchoolEnrollment: student?.currentSchoolEnrollment || "" as any,
                iep: student?.iep || "" as any,
                retention: student?.retention || "",
                englishLanguageLearner: student?.englishLanguageLearner || ""
            }}
            disabledPrograms={disabledPrograms || []}
        />
    )
}

export default Edit
